<template>
  <div>
    <div v-if="$store.state.paymentDetails.AdditionalRequests.length">
      <p class="text-center text-h6 secondary--text font-weight-light">
        You have
        {{ $store.state.paymentDetails.AdditionalRequests.length }} additional
        payment request(s).
      </p>

      <div class="mt-5 d-flex flex-column align-center">
        <v-btn
          v-for="(request, index) in $store.state.paymentDetails
            .AdditionalRequests"
          :key="index"
          @click="rerouteToRequest(request)"
          class="mb-3"
          depressed
          large
          color="secondary"
          block
        >
          {{ requestDescription(request) }}
        </v-btn>
      </div>

      <v-divider class="my-12 mx-12"></v-divider>
    </div>
  </div>
</template>

<script>
export default {
  name: 'additionalRequests',
  methods: {
    requestDescription(request) {
      if (request.Frequency === "OneOff") return 'View payment details'
      return 'Create standing order'
    },
    rerouteToRequest(additionalRequest) {
      this.$store.commit('CLEAR_STORE')
      this.$router.push({ path: `/?requestid=${additionalRequest.Request.Reference}`, })
    }
  }
}
</script>

<style>
</style>