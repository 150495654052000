<template>
  <div>
    <div class="d-flex flex-column">
      <div>
        <PaymentStatus v-if="paymentStatus" :paymentStatus="paymentStatus" />
        <div v-else class="mt-12 text-center">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <p class="mt-5 text-h6 primary--text">Fetching payment status...</p>
        </div>
      </div>

      <div v-if="!$store.state.modulrID" class="text-center red--text mt-10">
        <p class="text-h4">Oops...</p>
        <p>
          We were unable to fetch the payment status. Please check your bank
          statement or contact your agent for confirmation.
        </p>
      </div>

      <v-divider class="my-12 mx-12"></v-divider>

      <AdditionalRequests />

      <p class="text-center mt-5 grey--text">
        Would you like to see your agency use
        <a v-if="this.$store.state.theme === 'payagent'" href="https://agentos.com/" target="_blank">payAgent</a><a v-else href="https://calmony.co" target="_blank">CalmonyPay</a>? <br />
        Get in touch today!
      </p>
    </div>
  </div>
</template>

<script>
import AdditionalRequests from "@/components/AdditionalRequests.vue";
import PaymentStatus from "@/components/PaymentStatus.vue";
import { API } from "aws-amplify";

export default {
  name: "PaymentModulrDone",
  components: {
    AdditionalRequests,
    PaymentStatus,
  },
  created() {
    if (!this.$store.state.modulrID) {
      // assuming sessionStorage has not survived - check cookies for values
      // Cookies are set in PaymentConfirm.vue and StandingOrderConfirm.vue
      const modulrID = this.getCookie("modulrID");
      this.$store.commit("SET_MODULR_ID", modulrID);

      const requestID = this.getCookie("payAgentRequestID");
      this.$store.commit("SET_REQUEST_ID", requestID);

      const paymentDetails = this.getCookie("payAgentPaymentDetails");
      const parsedPaymentDetails = JSON.parse(paymentDetails);
      this.$store.commit("SET_PAYMENT_DETAILS", parsedPaymentDetails);

      const paymentDetailsSubmitted = this.getCookie(
        "payAgentPaymentDetailsSubmitted"
      );
      const parsedPaymentDetailsSubmitted = JSON.parsh(paymentDetailsSubmitted);
      this.$store.commit(
        "SET_PAYMENT_DETAILS_TO_SUBMIT",
        parsedPaymentDetailsSubmitted
      );
    }
  },
  mounted() {
    if (this.$store.state.paymentDetails.Request.Frequency === "OneOff")
      this.getOneOffPaymentStatus();
    else this.getStoPaymentRequest();
  },
  data() {
    return {
      paymentStatus: "",
    };
  },
  methods: {
    rerouteHome() {
      this.$store.commit("CLEAR_STORE");
      this.$router.push({ path: `/?requestid=${this.$store.state.requestID}` });
    },
    getOneOffPaymentStatus() {
      API.get(
        "paymentAppRest",
        `/oneOff-status?requestID=${this.$store.state.requestID}&executionID=${this.$store.state.modulrID}`
      ).then((res) => {
        this.paymentStatus = res.data;
      });
    },
    getStoPaymentRequest() {
      API.get(
        "paymentAppRest",
        `/standingOrder-status?requestID=${this.$store.state.requestID}&executionID=${this.$store.state.modulrID}`
      ).then((res) => {
        this.paymentStatus = res.data;
      });
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
};
</script>

<style scoped>
</style>
