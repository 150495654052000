<template>
  <div>
    <!-- Component used in the /done view - views/static/PaymentModulrDone -->
    <div class="details mt-5 mx-auto text-center">
      <!-- Status -->
      <div class="mb-8">
        <p
          class="text--darken-1 font-weight-light mb-1 text-caption"
          :class="textColor"
        >
          Payment status:
        </p>

        <p
          class="mb-0 text--darken-1 text-h5 font-weight-bold"
          :class="textColor"
        >
          <span v-if="paymentStatus === 'EXECUTED'">Payment completed</span>
          <span v-else>{{ paymentStatus }}</span>
        </p>

        <p
          v-if="paymentStatus !== 'EXECUTED'"
          class="mt-0 text--darken-1 text-caption font-weight-light"
          :class="textColor"
        >
          {{ statusDescription }}
        </p>
      </div>
      <!-- Image -->
      <div
        v-if="positiveStatus"
        class="py-5 pa-sm-3 my-3 d-flex justify-center"
      >
        <v-img v-if="this.$store.state.theme === 'payagent'"
          max-width="150"
          class="svg-image"
          src="../assets/PayAgentBranding/undraw_payment_complete.svg"
          contain
        />
        <v-img v-else
          max-width="150"
          class="svg-image"
          src="../assets/CalmonyPayBranding/undraw_payment_complete.svg"
          contain
        />
      </div>
      <!-- Bank statement ref -->
      <div v-if="positiveStatus" class="mb-3r">
        <p
          class="text--darken-1 font-weight-light mb-1 text-caption"
          :class="textColor"
        >
          Bank statement reference:
        </p>
        <p class="text--darken-1 font-weight-bold" :class="textColor">
          {{ $store.state.paymentDetails.BankAccountName }}
        </p>
      </div>
      <!-- Request id -->
      <div class="mb-3">
        <p
          class="text--darken-1 font-weight-light mb-1 text-caption"
          :class="textColor"
        >
          Payment reference:
        </p>
        <p class="text--darken-1 font-weight-bold" :class="textColor">
          {{ $store.state.requestID }}
        </p>
      </div>
      <!-- Amount paid -->
      <div>
        <p
          class="text--darken-1 font-weight-light mb-1 mt-0 text-caption"
          :class="textColor"
        >
          Amount paid:
        </p>
        <p class="text--darken-1 font-weight-bold" :class="textColor">
          {{ $store.state.paymentDetailsToSubmit.total | formatAmount }}
        </p>
      </div>

      <v-btn
        v-if="paymentStatus === 'Rejected'"
        @click="rerouteHome"
        class="mb-3 mt-8 mx-auto"
        x-large
        depressed
        outlined
        color="primary"
        width="70%"
        >Try again</v-btn
      >
    </div>

    <!-- Signature and message -->
    <div class="mt-10">
      <p
        v-if="positiveStatus"
        class="mb-0 text-center text-h6 text-sm-h5 secondary--text"
      >
        Thank you for using <span v-if="this.$store.state.theme === 'payagent'">payAgent</span> <span v-else>CalmonyPay</span>.
      </p>

      <div
        tabindex="0"
        v-if="$store.state.paymentDetails.IsGenericLink"
        class="text-center secondary--text"
      >
        <p class="text-center text-h5 my-0">
          {{ $store.state.paymentDetails.Company }}
        </p>
        <p class="text-center text-sm-body-1 my-0 font-weight-light">
          Tel: {{ $store.state.paymentDetails.BranchPhone }}
        </p>
      </div>
      <div v-else tabindex="0" class="text-center mt-3 secondary--text">
        <p
          class="
            text-center text-sm-body-1
            font-weight-regular
            my-0
            font-weight-light
          "
        >
          {{ $store.getters.capitalizeStaffName }}
        </p>
        <p class="text-center text-sm-body-1 mt-1 mb-0 font-weight-light">
          {{ $store.state.paymentDetails.Company }}
        </p>
        <p class="text-center text-sm-body-1 mt-0 mb-3 font-weight-light">
          Tel: {{ $store.state.paymentDetails.BranchPhone }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'paymentStatus',
  props: {
    paymentStatus: {
      type: String
    }
  },
  data() {
    return {
      positiveStatusEnums: [
        'EXECUTED',
        'AcceptedSettlementInProcess',
        'AcceptedSettlementCompleted',
        'AcceptedWithoutPosting',
        'AcceptedCreditSettlementCompleted'
      ]
    }
  },
  methods: {
    rerouteHome() {
      this.$store.commit('CLEAR_STORE')
      this.$router.push({ path: `/?requestid=${this.$store.state.requestID}`, })
    }
  },
  computed: {
    statusDescription() {
      if (this.paymentStatus === 'Created') return 'Awaiting Processing'
      if (this.paymentStatus === 'AWAITING_CONSENT') return 'Waiting for you to approve the payment in your bank`s app.'
      if (this.paymentStatus === 'CONSENT_REJECTED') return 'You or your bank have cancelled this process, please try again.'
      if (this.paymentStatus === 'EXECUTED') return 'Payment accepted by your bank and will be executed soon.'
      if (this.paymentStatus === 'ER_EXTSYS') return 'External System error. Please try again.'
      if (this.paymentStatus === 'ER_EXPIRED') return 'You did not complete the payment request.'
      if (this.paymentStatus === 'ER_GENERAL') return 'Unknown Payment rejection. Please try again.'
      if (this.paymentStatus === 'Pending') return 'Payment is pending. Try refreshing this page in a few minutes for an update.'
      if (this.paymentStatus === 'Rejected') return 'Payment rejected. Please try again.'
      if (this.paymentStatus === 'AcceptedSettlementInProcess') return 'Payment accepted for execution.'
      if (this.paymentStatus === 'AcceptedSettlementCompleted') return 'Settlement on your account has been completed.'
      if (this.paymentStatus === 'AcceptedWithoutPosting') return 'Payment accepted.'
      if (this.paymentStatus === 'AcceptedCreditSettlementCompleted') return 'Payment complete.'
      return ''
    },
    textColor() {
      if (this.paymentStatus === 'Created') return 'grey--text'
      if (this.paymentStatus === 'AWAITING_CONSENT') return 'grey--text'
      if (this.paymentStatus === 'CONSENT_REJECTED') return 'red--text'
      if (this.paymentStatus === 'EXECUTED') return 'green--text'
      if (this.paymentStatus === 'ER_EXTSYS') return 'red--text'
      if (this.paymentStatus === 'ER_EXPIRED') return 'red--text'
      if (this.paymentStatus === 'ER_GENERAL') return 'red--text'
      if (this.paymentStatus === 'Pending') return 'grey--text'
      if (this.paymentStatus === 'Rejected') return 'red--text'
      if (this.paymentStatus === 'AcceptedSettlementInProcess') return 'green--text'
      if (this.paymentStatus === 'AcceptedSettlementCompleted') return 'green--text'
      if (this.paymentStatus === 'AcceptedWithoutPosting') return 'green--text'
      if (this.paymentStatus === 'AcceptedCreditSettlementCompleted') return 'green--text'
      return 'grey--text'
    },
    positiveStatus() {
      if (this.positiveStatusEnums.includes(this.paymentStatus)) return true
      return false
    }
  }
}
</script>

<style>
</style>